import PropTypes from 'prop-types';

export default function WithLayout({
        component: Component,
        layout: Layout,
        ...rest
    }) {

    return (
        <Layout>
            <Component
                {...rest}
            />
        </Layout>
    )
}

WithLayout.propTypes = {
    component: PropTypes.elementType.isRequired,
    layout: PropTypes.elementType.isRequired,
};
