import { combineReducers } from 'redux';

// reducer import
import snackbarReducer from './snackbarReducer';

//-----------------------|| COMBINE REDUCER ||-----------------------//

const rootReducer = combineReducers({
    snackbar: snackbarReducer
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;
