import React from 'react';
import { Router } from "react-router";
import { createBrowserHistory } from 'history';
import { Layout } from './routes';
import Snackbar from './ui-component/extended/Snackbar';
import reducer from './store/reducer';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

const browserHistory = createBrowserHistory();
// const LayoutContainer = React.lazy(() => import('./routes').then(({ Layout }) => ({ default: Layout })))
const store = createStore(reducer);

function RenderDeviceContainers() {
  return (
    <React.Fragment>
      {/* <LayoutContainer/> */}
      <Layout />
      <Snackbar />
    </React.Fragment>
  );
}

const App = () => (
  <Provider store={store}>
    <Router history={browserHistory} >
      <RenderDeviceContainers />
    </Router>
  </Provider>
)

export default App;
