import { lazy } from 'react'
import Loadable from 'ui-component/Loadable';
import { BrowserRouter, Route, Switch, Redirect, withRouter } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { compose } from 'redux';
import { IntlProps } from '../';

import WithLayout from 'WithLayout'
import MainLayout from 'views/layout/mainLayout'

const AboutScreen = Loadable(lazy(() => import('screens/AboutScreen')));
const LandingScreen = Loadable(lazy(() => import('screens/LandingScreen')));
const ContactScreen = Loadable(lazy(() => import('screens/ContactScreen')));
const TicketScreen = Loadable(lazy(() => import('screens/TicketScreen')));
const TicketSuccessScreen = Loadable(lazy(() => import('screens/TicketScreen/success')));
const SpeakerScreen = Loadable(lazy(() => import('screens/SpeakerScreen')));
const NewsScreen = Loadable(lazy(() => import('screens/NewsScreen/view')));
const ReportScreen = Loadable(lazy(() => import('screens/ReportScreen')));
const ReportSummaryScreen = Loadable(lazy(() => import('screens/ReportScreen/summary')));
const LoginScreen = Loadable(lazy(() => import('screens/UserScreen/login')));
const CheckInScreen = Loadable(lazy(() => import('screens/UserScreen/checkIn')));
const EventScreen = Loadable(lazy(() => import('screens/EventScreen')));

const LayoutComponent = (props) => {
  
  return (
        <Switch>
            <Route path='/'
              exact
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={LandingScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/contact'
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={ContactScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/blog-view/:blogId'
              exact={true}
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={NewsScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/booking/:aimag'
              exact={true}
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={ReportScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/tickets-summary'
              exact={true}
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={ReportSummaryScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/buyticket/:ticketId'
              exact={true}
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={TicketScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/buyticket'
              exact={true}
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={TicketScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/tickets'
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={TicketScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/events'
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={EventScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/buyticketSuccess'
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={TicketSuccessScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/speakers'
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={SpeakerScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/about'
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={AboutScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/login'
              exact={true}
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={LoginScreen}
                layout={MainLayout}
                />
            )}/>

            <Route path='/checkIn/:orderNo'
              exact={true}
              component={(matchProps) => (
                <WithLayout
                {...matchProps}
                component={CheckInScreen}
                layout={MainLayout}
                />
            )}/>

            {/* <Redirect to='/'/> */}
        </Switch>
  );
};

// export const Layout = compose(
//   injectIntl,
//   withRouter,
//   connect(mapStateToProps, mapDispatchToProps),
// )(LayoutComponent) as any;

export const Layout = withRouter(LayoutComponent);