import { Link } from 'react-router-dom'

const Header = () => (
    <header id="home" className="header-area">            
        <div id="header-sticky" className="menu-area sticky-menu">
            <div className="container">
                <div className="second-menu">
                    <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-3">
                            <div className="logo">
                                <Link to="/"><img src="/img/newLogo.png" alt="logo"/></Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-9">
                            <div className="responsive"><i className="icon dripicons-align-right"></i></div>
                            <div className="main-menu text-right text-xl-center">
                                <nav id="mobile-menu">
                                    <ul>
                                        <li className="active">
                                            <Link to="/">Нүүр</Link>
                                        </li>
                                        <li>
                                            <Link to="/events">Арга хэмжээ</Link>												
                                        </li>
                                        {/* <li>
                                            <Link to="/tracks">Хөтөлбөр</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/speakers">Илтгэгчид</Link>
                                        </li>
                                        {/* <li className="has-sub">
                                            <Link to="#">Shop +</Link>
                                            <ul>
                                                <li><Link to="/shop">Shop Page</Link></li>
                                                <li><Link to="/shop-details">Shop Details</Link></li>
                                            </ul>
                                        </li> */}
                                        {/* <li><Link to="/news">Мэдээ</Link></li> */}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-xl-3 text-right d-none d-xl-block">
                            <div className="header-btn second-header-btn">
                                    <Link to="/tickets" className="btn"><i className="far fa-ticket-alt"></i> Тасалбар авах</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
)

export default Header