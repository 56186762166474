import Footer from "./footer"
import Header from "./header"

const MainLayout = ({ children }) => (
    <>
        <Header />
            {children}
        <Footer />
    </>
)

export default MainLayout