import { Link } from 'react-router-dom'

const Footer = () => (
    <footer className="footer-bg footer-p" style={{ backgroundImage: "url(img/footer_bg_img.jpg)", backgroundSize: "cover" }}>
        <div className="footer-top">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
                        <div className="footer-widget pt-120 mb-30">
                            <div className="logo mb-35">
                                <Link to="/"><img src="/img/logo_white.png" alt="logo"/></Link>
                            </div>
                            <div className="footer-text mb-20">
                                <p>Бүх 21 аймгийн 1998 онд ЕБС төгсөгчдийн холбоодын нэгдэл</p>
                            </div>
                            <div className="footer-social">                                    
                                <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                                <Link to="#"><i className="fab fa-twitter"></i></Link>
                                <Link to="#"><i className="fab fa-instagram"></i></Link>
                                <Link to="#"><i className="fab fa-google-plus-g"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-wrap pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-12">						
                        <div className="copyright-text text-center">
                            <div className="footer-link">
                                <ul>
                                    <li><Link to="/about">Бидний тухай</Link></li>
                                    <li><Link to="/">Хөтөлбөр</Link></li>
                                    <li><Link to="/">Мэдээ</Link></li>
                                    <li><Link to="/contact">Холбоо барих</Link></li>
                                    <li><Link to="/tickets">Тасалбар</Link></li>
                                    <li><Link to="/contact">Байршил</Link></li>
                                </ul>
                            </div>                              
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer