import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Alert, Button, Fade, Grow, IconButton, Slide } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// assets
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from 'store/reducer';

// animation function
function TransitionSlideLeft(props) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
    return <Grow {...props} />;
}

// animation options
const transition = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade: Fade
};

//-----------------------|| SNACKBAR ||-----------------------//

const Snackbar = () => {
    const [open, setOpen] = React.useState(false);
    const snackbarInitial = useSelector((state: RootState) => state.snackbar);

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        setOpen(snackbarInitial.open);
    }, [snackbarInitial.action, snackbarInitial.open]);

    return (
        <React.Fragment>
            {/* alert snackbar */}
            {snackbarInitial.variant === 'alert' && (
                <MuiSnackbar
                    TransitionComponent={transition[snackbarInitial.transition]}
                    // anchorOrigin={snackbarInitial.anchorOrigin}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        variant="filled"
                        severity={snackbarInitial.alertSeverity}
                        sx={{
                            bgcolor: snackbarInitial.alertSeverity + '.dark',
                            color: snackbarInitial.alertSeverity === 'warning' ? 'grey.800' : ''
                        }}
                        action={
                            <React.Fragment>
                                {snackbarInitial.close !== false && (
                                    <IconButton size="small" aria-label="close" color="primary" onClick={handleClose}  component="label">
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </React.Fragment>
                        }
                    >
                        {snackbarInitial.message}
                    </Alert>
                </MuiSnackbar>
            )}
        </React.Fragment>
    );
};

export default Snackbar;
